import { useEffect } from "react";



export function AlertMessage({showAlertModal, closeModal, alertData}) {

useEffect(()=>{
    console.log("alert component ");
}, []);

    return (<>
        {showAlertModal && (
            <>
                <div className="modal-backdrop fade show alert" ></div>
                <div className="modal fade show alert" id="alertModal" style={{ display: 'block'}}I data-bs-backdrop="true" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-body contentWrapper noPadding">
                                <div className="d-flex align-items-center justify-content-between w-100" >

                                    <div className="d-flex align-items-center">
                                        <img className="px-2" src="/images/icons/Yellow_Warning_Icon.svg" /><h6 className="modal-title fs-5 ps-2 pe-5" id="exampleModalLabel">{alertData.header}</h6>
                                    </div>
                                    <div className="p-3">
                                        <img src="/images/icons/Close_Icon.svg" data-bs-dismiss="modal" aria-label="Close" onClick={closeModal}></img>
                                    </div>
                                </div>
                                <div className="p-2 px-5 pb-3">
                                    {alertData.message}
                                </div>
                            </div>
                            <div className="d-flex justify-content-end" style={{ background: '#dcdbd7' }}>
                                <button type="button" className=" col btn btn-light" data-bs-dismiss="modal" onClick={closeModal}>Ok</button>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )}
    </>);
}