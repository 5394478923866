import { useEffect, useState } from "react";
import { deleteProducts } from "./ProductAction";
import { AlertMessage } from "../alert/AlertMessage.js";


function DeleteProduct({ showModal, closeModal, productsdata }) {

    const [prod, setProd] = useState([]);
    const [alertMessageData, setAlertMessageData] = useState({header:'', message:'', status: ''});
    const [showAlertStatus, setShowAlertStatus] = useState(false);

    const deleteProduct = () => {
        if (prod != null && prod.length > 0) {
            const prodNos = [];
            prod.forEach((ele) => {
                if ((ele.productCode != null && ele.productCode != '') && (ele.productNo != null && ele.productNo != '')) {
                    prodNos.push(ele.productNo);
                }
            })
            deleteProducts(prodNos).then(async (resp) => {
                const respMsg = await resp.json();
                setTimeout(() => {
                    if (resp.status == 200) {
                        setShowAlertStatus(true);
                        setAlertMessageData({ header: 'Alert Message', message: respMsg.msg, status:1 });
                    } else {
                        setShowAlertStatus(true);
                        setAlertMessageData({ header: 'Alert Message', message: respMsg.msg, status:2 });
                    }
                }, 2);
            });
        }
    }

    const updateProductsSelected = (prods) => {
        setProd(prod=>[]);
        let producList = JSON.parse(prods);
        setProd(producList);
    }

    function handleAlertModalChanges() {
        setShowAlertStatus(!showAlertStatus);
        if(alertMessageData.status===1){
            closeModal();
        }
    }

    useEffect(()=>{
        updateProductsSelected(productsdata);
    }, [productsdata]);

    return (
        <>
            {showAlertStatus == true ?
                <AlertMessage showAlertModal={showAlertStatus} closeModal={handleAlertModalChanges} alertData={alertMessageData} />
                :
                ""
            }
            {showModal && (
                <>
                    <div className="modal-backdrop fade show" ></div>
                    <div className="modal fade show" id="deleteProdModal" style={{ display: 'block' }} data-bs-backdrop="true" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-body contentWrapper noPadding">
                                    <div className="d-flex align-items-center justify-content-between w-100" >

                                <div className="d-flex align-items-center">
                                    <img className="px-2" src="/images/icons/Yellow_Warning_Icon.svg" /><h6 className="modal-title fs-5 ps-2 pe-5" id="exampleModalLabel">Do you want to delete the selected items?</h6>
                                    </div>
                                    <div className="p-3">
                                    <img src="/images/icons/Delete_Help_Icon.png" data-bs-dismiss="modal" aria-label="Close" onClick={closeModal}></img>
                                    </div>
                                    </div>
                                    <div className="p-2 px-5 pb-3">
                                    Information of the selected items will be removed from the product master. Confirm to proceed.
                                    </div>
                                </div>
                                <div className="container">

                                <div className="row p-2 gap-2" style={{ background: '#dcdbd7' }}>
                                    <button type="button" className="col btn btn-primary" onClick={deleteProduct}>Confirm  </button>
                                    <button type="button" className=" col btn btn-light" data-bs-dismiss="modal" onClick={closeModal}>Cancel </button>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

export default DeleteProduct;