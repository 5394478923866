import React, { useContext, useEffect, useState } from "react";
import Log from "./Log.js";
import MasterTableHeader from "./MasterTableHeader.js";
import MasterTableFooter from "./MasterTableFooter.js";
import ProductListing from "./ProductListing.js";

export default function Product() {
  const [showAddProd, setShowAddProd] = useState(false);
  const [selectedRows, setSelectedRows] = useState([ ]);
  const [filter, setFilter] = useState({});
  const [reload, setReload] = useState(false);

  function handleSelectedRows(data) {
    let productList = [];
    if (selectedRows.length > 0) {
      setSelectedRows(selectedRows => []);
    }
    data.map((prod) => {
      productList.push(prod.original);
    })
    setSelectedRows(productList)
  }

  useEffect(() => {
    console.log("product page: ", selectedRows);
  }, [showAddProd, filter]);

   function handleChange(event) {
    setShowAddProd(event.target.value);
  }

  function updateProductListing(data) {
    console.log("handleDataFromMasterFooterAddProd : ", data);
    setReload(!reload);
  }

  function handleDataFromEditProd(data) {
    console.log("handleDataFromMasterFooterEditProd : ", data);

    setReload(!reload);
  }

  function handleFilterChange(data) {
    console.log("handleFilterChange : data : ", data);
    let quickSettingData = data;
    setFilter({ ...quickSettingData });
    console.log("filter : ", filter, "  :  ", quickSettingData);
  }

  return (
    <>
      <div className="h-100 w-100 " >
        <div className="h-100">
          <div >
            <MasterTableHeader />
          </div>
          <div className="w-100 h-100 d-flex flex-grow" >
            <div className="col-9 overflow-y-auto">
              <ProductListing selectedRowsMethod={handleSelectedRows} filter={filter} reloadProdListing={reload} />
            </div>
            <div className="col-3">
              <Log selectedRowDatas={JSON.stringify(selectedRows)} />
            </div>
          </div>
          <div>
            <MasterTableFooter selectedRowDatas={JSON.stringify(selectedRows)} showAddProdModal={updateProductListing} filterDetail={handleFilterChange} />
          </div>
        </div>
      </div>


    </>
  );
}
