import { useEffect, useState } from "react";
import AddProduct from "./AddProduct";
import QuickSetting from "./QuickSetting";
import EditProduct from "./EditProduct";
import DeleteProduct from "./DeleteProduct";


function MasterTableFooter(props) {

    const [addProduct, setAddProduct] = useState(false);
    const [importProduct, setImportProduct] = useState(false);
    const [exportProduct, setExportProduct] = useState(false);
    const [quickSetting, setQuickSetting] = useState(false);
    const [deleteProduct, setDeleteProduct] = useState(false);
    const [productSelectedStatus, setProductSelectedStatus] = useState(false);
    const [editProd, setEditProd] = useState(false);
    const [editButtonDisabled, setEditButtonDisabled] = useState(false);
    const [productData, setProductData] = useState();
    const [productsData, setProductsData] = useState([]);
    const [filter, setFilter] = useState();
    // { si: '', speed: '', wob: '', flushing: '', comment: '' }

    const showAddProd = (event) => {
        setAddProduct((!addProduct));
        props.showAddProdModal(addProduct);
    }

    const showQuickSetting = () => {
        setQuickSetting(!quickSetting);
    }

    const showDeleteProd = () => {
        setDeleteProduct(!deleteProduct);
        props.showAddProdModal(deleteProduct);
    };
    const showEditProd = () => {
        setEditProd(!editProd);
        props.showAddProdModal(deleteProduct);
    };
    const updateProductSelected = (prod) => {
        setTimeout(() => {
            setProductData(prod);
        }, 1);
        setProductSelectedStatus(true);
    }

    const handleFilterChanges = (data) => {
        console.log("handleFilterChanges : ", data);
        props.filter = data;
    }

    function handleFilterValueChanges(data) {
        setFilter(data);
        props.filterDetail(data);
    }

    useEffect(() => {
        let prod = [];
        prod = JSON.parse(props.selectedRowDatas);
        if (prod != null && prod.length > 0) {
            updateProductSelected(prod[0]);
            if (prod.length > 1) {
                setEditButtonDisabled(true);
            } else {
                setEditButtonDisabled(false);
            }
        } else {
            setProductSelectedStatus(false);
            setEditButtonDisabled(false);
        }
    }, [props.selectedRowDatas, filter]);

    return (
        <>
            {addProduct === true ?
                <AddProduct showModal={addProduct} closeModal={showAddProd} />
                :
                ""
            }
            {editProd === true ?
                <EditProduct showModal={editProd} closeModal={showEditProd} productdata={productData} />
                :
                ""
            }

            {deleteProduct === true ? 
            <DeleteProduct showModal={deleteProduct} closeModal={showDeleteProd} productsdata={props.selectedRowDatas} />
            :
            ""
            }

            <div className="w-100 d-flex align-items-center justify-content-between productMasterFooter" >
             <div className="col-9 d-flex justify-content-between border-end border-dark-subtle">
                <div className='col-8 ms-3 m-2 me-4' >
                    <button className="footerBtn newPrdFooterButton mx-2" type="button" onClick={(e) => showAddProd(e)} >Add new product</button>
                    <button className="footerBtn whiteFooterButton mx-2" type="button" >Import</button>
                    <button className="footerBtn disabledFooterBtn ms-2" type="button" disabled>Export</button>

                </div>
                    <div className="d-flex justify-content-end m-2 col-2 position-relative">
                        <button className="footerBtn qkSettingFooterBtn" onClick={showQuickSetting} >Quick Settings</button>
                        {quickSetting === true ?
                            <QuickSetting filterDetails={handleFilterValueChanges} />
                            :
                            ""
                        }
                </div>
                </div>
                <div className="d-none">

                    <div className="col-3 logFooterMainDiv">
                        <div className="logFooterDiv">
                            <div className="logFooter">
                                {productSelectedStatus === true ?
                                    <>
                                            <button className="footerBtn whiteFooterButton ps-1" onClick={showDeleteProd}>Delete</button>
                                            <button className="footerBtn whiteFooterButton" onClick={showEditProd}>Edit</button>
                                    </>
                                    :
                                    <>
                                            <button className="footerBtn whiteFooterButton ps-1" onClick={showDeleteProd} disabled>Delete</button>
                                            <button className="footerBtn whiteFooterButton" onClick={showEditProd} disabled>Edit</button>
                                    </>
                                }

                            </div>
                        </div>
                    </div>
                </div>
                {productSelectedStatus === true ?
                    <div className="col-3 d-flex justify-content-around">
                        <button className="w-50 footerBtn whiteFooterButton mx-2" onClick={showDeleteProd}>Delete</button>
                        {editButtonDisabled === true?
                            <button className="w-50 footerBtn disabledFooterBtn me-2" disabled onClick={showEditProd}>Edit</button>
                            :
                            <button className="w-50 footerBtn whiteFooterButton me-2" onClick={showEditProd}>Edit</button>
                        }
                    </div>
                    :
                    <div className="col-3 d-flex align-items-center">
                        <button className="w-50 footerBtn disabledFooterBtn mx-2" disabled onClick={showDeleteProd}>Delete</button>
                        <button className="w-50 footerBtn disabledFooterBtn me-2" disabled onClick={showEditProd}>Edit</button>
                     </div>
                }
            </div>
        </>
    );
};

export default MasterTableFooter;