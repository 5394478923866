import { useState } from "react";
import { addProduct } from "./ProductAction";
import { AlertMessage } from "../alert/AlertMessage.js";

export default function AddProduct({ showModal, closeModal }) {

  const [SI, US] = useState(true);
  const [alertMessageData, setAlertMessageData] = useState({ header: '', message: '', status: '' });
  const [showAlertStatus, setShowAlertStatus] = useState(false);
  const [prod, setProd] = useState({
    productcode: '',
    productno: '',
    rpm_min: '',
    rpm_max: '',
    wob_metric: '',
    wob_imperial: '',
    flushingmin_metric: '',
    flushingmin_imperial: '',
    flushingmax_metric: '',
    flushingmax_imperial: '',
    comment: '',
  });

  const addNewProduct = () => {
    if (prod !== null && (prod.productcode !== null && prod.productcode !== '') && (prod.productno !== null && prod.productno !== '')) {
      addProduct(prod).then(async (resp) => {
        try {
          
          const respMsg = await resp.json();
          setTimeout(() => {
            
            if (resp.status ===200) {
              setShowAlertStatus(true);
              setAlertMessageData({ header: 'Alert', message: respMsg.msg, status: 1 });
            } else {
              setShowAlertStatus(true);
              setAlertMessageData({ header: 'Alert', message: respMsg.msg, status: 2 });
            }
          }, 2);
        } catch (error) {
          console.log("error : ", error);
          setShowAlertStatus(true);
          setAlertMessageData({ header: 'Alert', message: 'Failed to Add Product', status: 2 });
        }
        });

    }
  }

  const toggleInput = () => {
    US(!SI);
  }

  const handleInput = (key, value) => {
    setProd({ ...prod, [key]: value, });
  }

  function handleAlertModalChanges() {
    setShowAlertStatus(!showAlertStatus);
    if (alertMessageData.status === 1) {
      closeModal();
    }
  }

  return (

    <>
      {showAlertStatus ===true ?
        <AlertMessage showAlertModal={showAlertStatus} closeModal={handleAlertModalChanges} alertData={alertMessageData} />
        :
        ""
      }
      {showModal && (
        <>
          <div className="modal-backdrop fade show" ></div>
          <div className="modal fade show" id="exampleModal" style={{ display: 'block' }} data-bs-backdrop="true" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="d-flex justify-content-between align-items-center px-3" style={{ background: '#dcdbd7', height: '2.5rem', borderTopLeftRadius: '.5rem', borderTopRightRadius: '.5rem' }}>
                  <div id="exampleModalLabel" >Add new product</div>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={closeModal}></button>
                </div>
                <div className="modal-body contentWrapper noPadding">
                  <div className="productA">
                    <div className="addProduct">
                    <div className="w-100 d-flex justify-content-start align-items-center modalReqInfo"><div>Fields marked as</div><img className="requiredMarkIcon mx-1" alt="" src="/images/icons/Orange_Point_Icon.svg" /><div>are required.</div>
                        </div>
                      <div className="w-100">
                        <div className="" />
                        <div className="d-flex justify-content-between align-items-center px-3">
                          <div className="col">Units</div>
                          <div className="col">
                            <div className="segmentedControlD w-75" >
                              <div className="innerContainer">
                                <div className={SI === true ? 'internalsegmentOptionD' : 'internalsegmentOptionD1'} onClick={toggleInput}>
                                  <div className="option">SI</div>
                                </div>
                                <div className={SI === false ? 'internalsegmentOptionD' : 'internalsegmentOptionD1'} onClick={toggleInput}>
                                  <div className="option">US</div>
                                  <div className="separatorContainer" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="infoMarkContainer" />
                      </div>
                      <div className="groupHeader">
                        <b className="text">Product</b>
                      </div>
                      <div className="field8">
                        <div className="requiredMarkContainer1">
                          <img className="requiredMarkIcon" alt="" src="/images/icons/Orange_Point_Icon.svg" />
                        </div>
                        <div className="mainContent">
                          <div className="leftAccessory">
                            <div className="titleContainer1">
                              <div className="titleContainer2">
                                <label className="option">Product Code</label>
                              </div>
                            </div>
                          </div>
                          <div className="rightAccessory">
                            <input className="inputModal px-2" type="text" onChange={e => handleInput('productcode', e.target.value)} />
                            <div className="unitContainer1">
                            </div>
                          </div>
                        </div>
                        <div className="infoMarkContainer1">
                          <img className="infoIcon" alt="" src="/images/icons/Cream_Info_Icon.svg" />
                        </div>
                      </div>
                      <div className="field8">
                        <div className="requiredMarkContainer1">
                          <img className="requiredMarkIcon" alt="" src="/images/icons/Orange_Point_Icon.svg" />
                        </div>
                        <div className="mainContent">
                          <div className="leftAccessory">
                            <div className="titleContainer1">
                              <div className="titleContainer2">
                                <label className="option">Product Number</label>
                              </div>
                            </div>
                          </div>
                          <div className="rightAccessory">
                            <input className="inputModal px-2" type="text" onChange={e => handleInput('productno', e.target.value)} />
                            <div className="unitContainer1">
                            </div>
                          </div>
                        </div>
                        <div className="infoMarkContainer1">
                          <img className="infoIcon" alt="" src="/images/icons/Cream_Info_Icon.svg" />
                        </div>
                      </div>
                      <div className="groupHeader">
                        <b className="text">Drill Set Parameters</b>
                      </div>
                      <div className="field8">
                        <div className="requiredMarkContainer1">
                          <img className="requiredMarkIcon" alt="" src="/images/icons/Orange_Point_Icon.svg" />
                        </div>
                        <div className="mainContent">
                          <div className="leftAccessory">
                            <div className="titleContainer1">
                              <div className="titleContainer2">
                                <label className="option">Speed (Min.)</label>
                              </div>
                            </div>
                          </div>
                          <div className="rightAccessory2">
                            <input className="inputModal text-dark px-2" placeholder="0" onChange={e => handleInput('rpm_min', e.target.value)} />
                            <div className="unitContainer1">
                              <div className="option">RPM</div>
                            </div>
                          </div>
                        </div>
                        <div className="infoMarkContainer1">
                          <img className="infoIcon" alt="" src="/images/icons/Cream_Info_Icon.svg" />
                        </div>
                      </div>
                      <div className="field8">
                        <div className="requiredMarkContainer1">
                          <img className="requiredMarkIcon" alt="" src="/images/icons/Orange_Point_Icon.svg" />
                        </div>
                        <div className="mainContent">
                          <div className="leftAccessory">
                            <div className="titleContainer1">
                              <div className="titleContainer2">
                                <label className="option">Speed (Max.)</label>
                              </div>
                            </div>
                          </div>
                          <div className="rightAccessory2">
                            <input className="inputModal text-dark px-2" placeholder='0' onChange={e => handleInput('rpm_max', e.target.value)} />
                            <div className="unitContainer1">
                              <div className="option">RPM</div>
                            </div>
                          </div>
                        </div>
                        <div className="infoMarkContainer1">
                          <img className="infoIcon" alt="" src="/images/icons/Cream_Info_Icon.svg" />
                        </div>
                      </div>
                      <div className={SI === true ? 'field8' : 'd-none'}>
                        <div className="requiredMarkContainer1">
                          <img className="requiredMarkIcon" alt="" src="/images/icons/Orange_Point_Icon.svg" />
                        </div>
                        <div className="mainContent">
                          <div className="leftAccessory">
                            <div className="titleContainer1">
                              <div className="titleContainer2">
                                <label className="option">WOB</label>
                              </div>
                            </div>
                          </div>
                          <div className="rightAccessory2">
                            <input className="inputModal text-dark px-2" type='text' placeholder='0' onChange={e => handleInput('wob_metric', e.target.value)} />                            <div className="unitContainer1">
                              <div className="option">kN</div>
                            </div>
                          </div>
                        </div>
                        <div className="infoMarkContainer1">
                          <img className="infoIcon" alt="" src="/images/icons/Cream_Info_Icon.svg" />
                        </div>
                      </div>
                      <div className={SI === true ? 'field8' : 'd-none'}>
                        <div className="requiredMarkContainer1">
                          <img className="requiredMarkIcon" alt="" src="/images/icons/Orange_Point_Icon.svg" />
                        </div>
                        <div className="mainContent">
                          <div className="leftAccessory">
                            <div className="titleContainer1">
                              <div className="titleContainer2">
                                <label className="option">Flushing (Min.)</label>
                              </div>
                            </div>
                          </div>
                          <div className="rightAccessory2">
                            <input className="inputModal text-dark px-2" type='text' placeholder='0' onChange={e => handleInput('flushingmin_metric', e.target.value)} />
                            <div className="unitContainer1">
                              <div className="option">lpm</div>
                            </div>
                          </div>
                        </div>
                        <div className="infoMarkContainer1">
                          <img className="infoIcon" alt="" src="/images/icons/Cream_Info_Icon.svg" />
                        </div>
                      </div>
                      <div className={SI === true ? 'field8' : 'd-none'}>
                        <div className="requiredMarkContainer1">
                          <img className="requiredMarkIcon" alt="" src="/images/icons/Orange_Point_Icon.svg" />
                        </div>
                        <div className="mainContent">
                          <div className="leftAccessory">
                            <div className="titleContainer1">
                              <div className="titleContainer2">
                                <label className="option">Flushing (Max.)</label>
                              </div>
                            </div>
                          </div>
                          <div className="rightAccessory2">
                            <input className="inputModal text-dark px-2" type='text' placeholder='0' onChange={e => handleInput('flushingmax_metric', e.target.value)} />
                            <div className="unitContainer1">
                              <div className="option">lpm</div>
                            </div>
                          </div>
                        </div>
                        <div className="infoMarkContainer1">
                          <img className="infoIcon" alt="" src="/images/icons/Cream_Info_Icon.svg" />
                        </div>
                      </div>
                      <div className={SI === false ? 'field8' : 'd-none'}>
                        <div className="requiredMarkContainer1">
                          <img className="requiredMarkIcon" alt="" src="/images/icons/Orange_Point_Icon.svg" />
                        </div>
                        <div className="mainContent">
                          <div className="leftAccessory">
                            <div className="titleContainer1">
                              <div className="titleContainer2">
                                <label className="option">WOB</label>
                              </div>
                            </div>
                          </div>
                          <div className="rightAccessory2">
                            <input className="inputModal text-dark px-2" type='text' placeholder='0' onChange={e => handleInput('wob_imperial', e.target.value)} />
                            <div className="unitContainer1">
                              <div className="option">lb</div>
                            </div>
                          </div>
                        </div>
                        <div className="infoMarkContainer1">
                          <img className="infoIcon" alt="" src="/images/icons/Cream_Info_Icon.svg" />
                        </div>
                      </div>
                      <div className={SI === false ? 'field8' : 'd-none'}>
                        <div className="requiredMarkContainer1">
                          <img className="requiredMarkIcon" alt="" src="/images/icons/Orange_Point_Icon.svg" />
                        </div>
                        <div className="mainContent">
                          <div className="leftAccessory">
                            <div className="titleContainer1">
                              <div className="titleContainer2">
                                <label className="option">Flushing (Min.)</label>
                              </div>
                            </div>
                          </div>
                          <div className="rightAccessory2">
                            <input className="inputModal text-dark px-2" type='text' placeholder='0' onChange={e => handleInput('flushingmin_imperial', e.target.value)} />
                            <div className="unitContainer1">
                              <div className="option">gpm</div>
                            </div>
                          </div>
                        </div>
                        <div className="infoMarkContainer1">
                          <img className="infoIcon" alt="" src="/images/icons/Cream_Info_Icon.svg" />
                        </div>
                      </div>
                      <div className={SI ===false ? 'field8' : 'd-none'}>
                        <div className="requiredMarkContainer1">
                          <img className="requiredMarkIcon" alt="" src="/images/icons/Orange_Point_Icon.svg" />
                        </div>
                        <div className="mainContent">
                          <div className="leftAccessory">
                            <div className="titleContainer1">
                              <div className="titleContainer2">
                                <label className="option">Flushing (Max.)</label>
                              </div>
                            </div>
                          </div>
                          <div className="rightAccessory2">
                            <input className="inputModal text-dark px-2" type='text' placeholder='0' onChange={e => handleInput('flushingmax_imperial', e.target.value)} />
                            <div className="unitContainer1">
                              <div className="option">gpm</div>
                            </div>
                          </div>
                        </div>
                        <div className="infoMarkContainer1">
                          <img className="infoIcon" alt="" src="/images/icons/Cream_Info_Icon.svg" />
                        </div>
                      </div>
                      {/* </>
                        
                      } */}
                      <div className="groupHeader">
                        <b className="text">Other</b>
                      </div>
                      <div className="w-100" style={{height: '60px'}}>
                        <textarea className="textFieldMultilineD placeholder5" type='text' placeholder='Add Comment (if any)' onChange={e => handleInput('comment', e.target.value)} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-end align-items-center pe-2 p-2" style={{ background: '#dcdbd7', borderBottomRightRadius: '.5rem', borderBottomLeftRadius: '.5rem' }}>
                  <button type="button" className="footerBtn newPrdFooterButton me-2" style={{width: '20%'}} onClick={addNewProduct}>Save  </button>
                  <button type="button" className="footerBtn whiteFooterButton" style={{width: '20%'}} data-bs-dismiss="modal" onClick={closeModal}>Cancel </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>

  );
};

