import { IconButton } from "@mui/material";

function MasterTableHeader(){

  function hideIcon(self) {
    self.style.backgroundImage = 'none';
}
    return (
        <>
        <div className="mainHeader p-3">
          <div className='mainHeaderTitle text-white' >
            Bit to Drill Product Master
          </div>
          <div className='d-flex align-items-center m-1' style={{gap: '1rem' }}>
            <div className='d-flex align-items-center headerIpSearch'>
                <img className='p-1' src="/images/icons/magnifying_glass.svg"></img><input id="headerSearch" type="text" name="search" onchange="hideIcon(this);" placeholder="Search" /> 
            </div>
            <IconButton><img src="/images/icons/network.svg" title="Network" /></IconButton>
            <button><img src="/images/icons/White_Info_Icon.svg" title="Info" /></button>
            <button><img src="/images/icons/setting.svg" title="Setting" /></button>
            <div style={{ display: 'flex', alignItems: 'center', gap: 4 }}>
            <button><img src="/images/icons/Person.svg" title="Account" /></button>
            <button><img src="/images/icons/arrow.svg" title="dropdown" /></button>
            </div>
          </div>
        </div>
       </>
    );
}

export default MasterTableHeader;