import { useState, useEffect } from "react";

export default function Log(props) {

    var property1 = "details-1";
    var className = "";
    var buttonDEnabled = true;
    var buttonDEnabled1 = true;
    const [productData, setProductData] = useState([{productCode: ''}]);
    const [prdDataLength, setPrdDataLength] = useState({len:0});
    const [showLogs, setShowLogs] = useState(false);
    const [productSelected, setProductSelected] = useState(false);
    let updatedProductList = [];

    const updateProductSelected = (prod) => {
        console.log("start set state : ", prod);
        if (prod.length === 1) {
            console.log("start set state 2 : ", prod[0]);
            var data = prod[0];
            updatedProductList = [];
            updatedProductList.push(prod[0]);
        } else {
            console.log("start set state 3 : ", prod);
            let prdList = [];
            updatedProductList = [];
            prod.map((ele) => {
                console.log("start set state 4 : ", prod);
                prdList.push(ele.productCode);
                updatedProductList.push(ele.productCode);
            })
            setProductData(updatedProductList);
        }
        console.log(updatedProductList, "updateProductSelected 5 : ", productData, prod);

        console.log("updateProductSelected 6 : ", prod, " : ", productData);
    }

    const handleLogView = () => {
        if (productSelected) {
            setShowLogs(!showLogs);
        }
    }

    useEffect(() => {
        let prodListing = [];
        prodListing = JSON.parse(props.selectedRowDatas);
        console.log(props.selectedRowDatas, "Log page loaded : ", prodListing);
        if (prodListing != null && prodListing.length > 0) {
            setProductData([]);
            console.log("prodListing : ", prodListing[0].productCode);
            let prdCodeList = [];
            setPrdDataLength({ 'len': prodListing.length });
            prodListing.map(element => {
                prdCodeList.push({ 'productCode': element.productCode });
            });
            console.log(" prdCodeList 3232 : ", prdCodeList);
            setProductData(...prdCodeList);
            console.log("updateProductSelected 2 : ", prodListing, " :: ", productData);
            setProductSelected(true);

        } else {
            setProductData(...[]);
            setShowLogs(false);
            setProductSelected(false);
            setPrdDataLength({ 'len': 0 });
        }
        console.log("prdDataLength : ", prdDataLength);
        console.log("prdDataLength 1212 : ", productData);
    }, [props.selectedRowDatas]);

    return (
        <>
            <div className="w-100 h-100 backColrPrdPage">
                <div className="row me-1" >
                    <div className="mt-2" style={{ marginLeft: '.15rem' }}>
                        {prdDataLength.len === 1 && (
                            <>
                                <div className="mb-2 logData"><span className="text-black logValue">{productData.productCode}</span></div>
                                <div className="d-flex align-items-center border-bottom border-dark-subtle logData "><label className="col logSubHeaderTxt">Created: </label><span className="d-flex align-items-end flex-column">29 Apr 2024 at 2:46 PM</span></div>
                                <div className="d-flex align-items-center border-bottom border-dark-subtle logData"><label className="col logSubHeaderTxt">Last Modified: </label><span className="d-flex align-items-end flex-column">29 Apr 2024 at 2:46 PM</span></div>
                                <div className="d-flex justify-content-between border-bottom border-dark-subtle logData"><label className="col logSubHeaderTxt">Last Modified by: </label><div className="text-end"><span className="text-dark col">user1.lastname</span></div></div>
                                <div className="d-flex justify-content-between logData"><div className="col logSubHeaderTxt">Shared with: </div><div className="d-flex align-items-end flex-column col"><span>user1.lastname</span><span>user2.lastname</span><span>user3.lastname</span></div></div>
                            </>
                        )
                        }
                        {prdDataLength.len > 1 && (
                            <>
                                <div className="mb-2 logData"><span className="logValue text-black">{prdDataLength.len} items </span></div>
                                <div className="d-flex align-items-center border-bottom border-dark-subtle logData"><label className="col logSubHeaderTxt">Created: </label><span className="text-end text-black" >8 - 30 Apr 2024</span></div>
                                <div className="d-flex align-items-center border-bottom border-dark-subtle logData"><label className="col logSubHeaderTxt">Last Modified: </label><span className="text-end text-black">9 - 30 Apr 2024</span></div>
                                <div className="d-flex justify-content-between border-bottom border-dark-subtle logData"><label className="col logSubHeaderTxt">Last Modified by: </label><div className="text-end text-black col row"><div className="">user1.lastname</div><div>user3.lastname</div></div></div>
                                <div className="d-flex justify-content-between logData"><div className="col logSubHeaderTxt">Shared with: </div><div className="text-end text-black row col"><span>user1.lastname</span><span>user2.lastname</span><span>user3.lastname</span></div></div>
                            </>
                        )
                        }
                    </div>
                </div>
                <button className="buttonD mt-2 ms-1 me-2" style={{ width: '-webkit-fill-available' }} onClick={handleLogView}>View All Logs</button>
            </div>

        </>
    );
}

