import { useEffect, useState } from "react";


export default function QuickSetting(props) {

    const [SI, US] = useState(true);
    const [filter, setFilter] = useState({ si: true, speed: true, wob: true, flushing: true, comment: true })

    function toggleInput() {
        console.log("product b4 togle : ", SI);
        // US(!SI);
        console.log("toggleInput 2 : ", filter);
        let filtrObj = filter;
        console.log("toggleInput 23 : ", filter);
        filtrObj.si = !filter.si;
        console.log("toggleInput 24 : ", filtrObj);
        setFilter({ ...filtrObj });
        console.log("toggleInput 4 : ", filter);
        // setTimeout(() => {
        console.log("toggleInput 3 : ", filter);
        console.log("toggleInput after toggle : ", SI);
        props.filterDetails(filtrObj);
        console.log("props value : ", props);
        // }, 1);
        console.log("toggleInput s : ", filter);
    }

    function handleFilterSpeedChange() {
        console.log("handleFilterFlushingChange 2 : ", filter);
        let filtrObj = filter;
        console.log("handleFilterFlushingChange 23 : ", filter);
        filtrObj.speed = !filter.speed;
        console.log("handleFilterFlushingChange 24 : ", filtrObj);
        setFilter({ ...filtrObj });
        console.log("handleFilterFlushingChange 4 : ", filter);
        // setTimeout(() => {
        console.log("handleFilterFlushingChange 2 : ", filter);
        props.filterDetails(filtrObj);
        console.log("props value : ", props);
        console.log("handleFilterSpeedChange 3 : ", filter);
        // }, 1);
    }


    function handleFilterWOBChange() {
        console.log("handleFilterFlushingChange 2 : ", filter);
        let filtrObj = filter;
        console.log("handleFilterFlushingChange 23 : ", filter);
        filtrObj.wob = !filter.wob;
        console.log("handleFilterFlushingChange 24 : ", filtrObj);
        setFilter({...filtrObj});
        console.log("handleFilterFlushingChange 4 : ", filter);
        // setTimeout(() => {
            console.log("handleFilterWOBChange 2 : ", filter);
            props.filterDetails(filtrObj);
            console.log("handleFilterWOBChange 3 : ", filter);
        // }, 1);
    }


    function handleFilterFlushingChange() {
        console.log("handleFilterFlushingChange 2 : ", filter);
        let filtrObj = filter;
        console.log("handleFilterFlushingChange 23 : ", filter);
        filtrObj.flushing = !filter.flushing;
        console.log("handleFilterFlushingChange 24 : ", filtrObj);
        setFilter({...filtrObj});
        console.log("handleFilterFlushingChange 4 : ", filter);
        // setTimeout(() => {
            console.log("handleFilterFlushingChange 2 : ", filter);
            props.filterDetails(filtrObj);
            console.log("handleFilterFlushingChange 3 : ", filter);
        // }, 1);
    }

    function handleFilterCommentsChange() {
        console.log("handleFilterFlushingChange 2 : ", filter);
        let filtrObj = filter;
        console.log("handleFilterFlushingChange 23 : ", filter);
        filtrObj.comment = !filter.comment;
        console.log("handleFilterFlushingChange 24 : ", filtrObj);
        setFilter({...filtrObj});
        console.log("handleFilterFlushingChange 4 : ", filter);
        // setTimeout(() => {
            console.log("handleFilterFlushingChange 2 : ", filter);
            props.filterDetails(filtrObj);
            console.log("handleFilterCommentsChange 3 : ", filter);
        // }, 1);
    }

    useEffect(() => {
        console.log("filter filter : ", filter);
    }, []);

    return (
        <>
            <div className="qkSettingToolTip p-3" >
                <div className="modal-body ps-2">
                    <div>
                        <div className="">
                            <div className="">
                                <div className="titlebar">
                                    <div className="titleContainer">
                                        <div className="qkSettingHeaderFont">Quick Settings</div>
                                    </div>
                                </div>
                                <div className="border-bottom pb-2 qkSettingBodyFont">
                                    <div className="d-flex align-items-center " >
                                        <div className="titleContainer1 col">
                                            <div className="title qkSettingBodyFont">Units</div>
                                        </div>
                                        <div className="deleteSegmentedControlD col" onClick={toggleInput} >
                                            <div className="innerContainer" style={{ width: '65%' }}>
                                                <div className={filter.si === true ? 'internalsegmentOptionD' : 'internalsegmentOptionD1'} >
                                                    <div className="title">SI</div>
                                                    <div className={filter.si === false ? "separatorContainer" : ''} />
                                                </div>
                                                <div className={filter.si === false ? 'internalsegmentOptionD' : 'internalsegmentOptionD1'} >
                                                    <div className="title">US</div>
                                                    <div className={filter.si === true ? "separatorContainer" : ''} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="separator" />
                                <div className="pt-2 qkSettingBodyFont">
                                    <div className="field11">
                                        <div className="mainContent">
                                            <div className="col">
                                                <div className="">
                                                    <div className="">
                                                        <div className="title">Filter</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col internalrightAccessoryD">
                                                <div className="row">
                                                    <div className="d-flex align-items-center">
                                                        <input  type="checkbox" value={filter.speed} checked={filter.speed} onChange={handleFilterSpeedChange} /><label className="ps-3" >Speed</label><br></br>
                                                    </div>
                                                    <div className="d-flex align-items-center">
                                                        <input  type="checkbox" value={filter.wob} checked={filter.wob} onChange={handleFilterWOBChange} /><label className="ps-3" >WOB</label><br></br>
                                                    </div>
                                                    <div className="d-flex align-items-center">
                                                        <input  type="checkbox" value={filter.flushing} checked={filter.flushing} onChange={handleFilterFlushingChange} /><label className="ps-3" >Flushing</label><br></br>
                                                    </div>
                                                    <div className="d-flex align-items-center">
                                                        <input  type="checkbox" value={filter.comment} checked={filter.comment} onChange={handleFilterCommentsChange} /><label className="ps-3" >Comments</label><br></br>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};
