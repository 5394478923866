
var basePathDev = "http://91.203.132.60:8081";
var basePathLocal = "http://localhost:8081";

var basePath = basePathDev;

export async function getProductList() {
  try {
    const response = await fetch(`${basePath}/api/products`);
    console.log("API resp : ", response);
    try {
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error fetching data from API', error);
      return [];
    }
  } catch (error) {
    console.error('Error fetching data from API', error);
    return [];
  }
}

export async function getProductById(prodId) {
  try {
    const response = await fetch(`${basePath}/api/product/${prodId}`);
    console.log("API resp : ", response);
    try {
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error fetching data from API', error);
      return {};
    }
  } catch (error) {
    console.error('Error fetching data from API', error);
    return {};
  }
}

export async function addProduct(newProd) {
  try {
    console.log("api ip : ", newProd);

    const response = await fetch(`${basePath}/api/product/add`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': "*",
        'Access-Control-Allow-Headers': "Origin, X-Requested-With, Content-Type, Accept"
      },
      body: JSON.stringify(newProd),
    });
    const data = response;
    return data;
  } catch (error) {
    console.error('Error fetching data from API', error);
    return { "status": 400, "msg": "Failed to Add the Product" };
  }
}

export async function editProduct(editProd) {
  try {
    console.log("api ip : ", editProd);

    const response = await fetch(`${basePath}/api/product/${editProd.id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': "*",
        'Access-Control-Allow-Headers': "Origin, X-Requested-With, Content-Type, Accept"
      },
      body: JSON.stringify(editProd),
    });
    console.log("API resp : ", response);
    // const data = await response.json();
    return response;
  } catch (error) {
    console.error('Error fetching data from API', error);
    return { "status": 400, "msg": "Failed to Edit the Product" };
  }
}

export async function deleteProducts(productNos) {
  try {
    console.log("delete api ip : ", productNos);

    const response = await fetch(`${basePath}/api/product/delete`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': "*",
        'Access-Control-Allow-Headers': "Origin, X-Requested-With, Content-Type, Accept"
      },
      body: JSON.stringify(productNos),
    });
    console.log("API resp : ", response);
    const data = response;
    return data;
  } catch (error) {
    console.error('Error fetching data from API', error);
    return { "status": 400, "msg": "Failed to Delete the Product" };
  }
}
