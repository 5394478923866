import { createColumnHelper } from "@tanstack/react-table";
import IndeterminateCheckbox from "./IndeterminateCheckbox";

export const epirocColumnDef = [
  {
      id: "select",
      accessorKey: 'selectMainColumn',
      header: ({ table }) => (
        <IndeterminateCheckbox
          {...{
            checked: table.getIsAllRowsSelected(),
            indeterminate: table.getIsSomeRowsSelected(),
            onChange: table.getToggleAllRowsSelectedHandler(),
          }}
          className='d-flex align-items-center ms-1'
        />
      ),
      cell: ({ row }) => (
        (row.getValue('productNo')!='' ?
        <IndeterminateCheckbox
          {...{
            checked: row.getIsSelected(),
            disabled: !row.getCanSelect(),
            indeterminate: row.getIsSomeSelected(),
            onChange: row.getToggleSelectedHandler(),
          }}
          className='d-flex align-items-center ms-1'
        />
      : '')
      ),
    },
//   columnHelper.accessor("id", {
//     header: "Id",
//   }),
{
  id: 'productDetail',
  accessorKey: 'productDetail',
  header: () => <span className="border-bold">Product Detail</span>,
  // footer: (props) => props.column.id,
  columns: [
    {
      accessorKey: 'productCode',
      size: 190,
      cell: (info) => info.getValue(),
      header: () => <div className="tableColumnSubHdr"> <div>Product Code </div><img src="/images/icons/Sorting_Icon.png" /></div>,
      enableColumnFilter: true,
      sortingFn: 'alphanumeric',
      sortDescFirst: false,
      enableSorting:false,
      // footer: (props) => props.column.id,
    },
    {
      accessorKey: 'productNo',
      accessorFn: (row) => row.productNo,
      size: 120,
      cell: (info) => info.getValue(),
      header: () => <div className="tableColumnSubHdr"> <div>Product Number </div><img src="/images/icons/Sorting_Icon.png" /></div>,
      enableColumnFilter: true,
      sortingFn: 'alphanumeric',
      sortDescFirst: false,
      enableSorting:false,
      // footer: (props) => props.column.id,
    },
  ],
},
{
  id: 'drillSetParameters',
  accessorKey: 'productDetail',
  header: () => <span>Drill Set Parameters</span>,
  // footer: (props) => props.column.id,
  columns: [
    {
      accessorKey: 'rpmMin',
      size: 120,
      header: () => <div className="tableColumnSubHdr"> <div>Speed (Min.) </div><img src="/images/icons/Sorting_Icon.png" /></div>,
      enableColumnFilter: true,
      sortingFn: 'basic',
      sortDescFirst: false,
      enableSorting:false,
      // footer: (props) => props.column.id,
    },
    {
      accessorKey: 'rpmMax',
      size: 120,
      header: () => <div className="tableColumnSubHdr"> <div>Speed (Max.) </div><img src="/images/icons/Sorting_Icon.png" /></div>,
      enableColumnFilter: true,
      sortingFn: 'basic',
      sortDescFirst: false,
      enableSorting:false,
      // footer: (props) => props.column.id,
    },
    {
      accessorKey: 'wob_metric',
      size: 70,
      header: () => <div className="tableColumnSubHdr"> <div>WOB </div><img src="/images/icons/Sorting_Icon.png" /></div>,
      enableColumnFilter: true,
      sortingFn: 'basic',
      sortDescFirst: false,
      enableSorting:false,
      // footer: (props) => props.column.id,
    },
    {
      accessorKey: 'flushingmin_metric',
      size: 120,
      header: () => <div className="tableColumnSubHdr"> <div>Flushing (Min.) </div><img src="/images/icons/Sorting_Icon.png" /></div>,
      enableColumnFilter: true,
      sortingFn: 'basic',
      enableSorting:false,
      // footer: (props) => props.column.id,
    },
    {
      accessorKey: 'flushingmax_metric',
      size: 120,
      header: () => <div className="tableColumnSubHdr"> <div>Flushing (Max.) </div><img src="/images/icons/Sorting_Icon.png" /></div>,
      enableColumnFilter: true,
      sortingFn: 'basic',
      enableSorting:false,
      // footer: (props) => props.column.id,
    },
    {
      accessorKey: 'wob_imperial',
      size: 70,
      header: () => <div className="tableColumnSubHdr"> <div>WOB </div><img src="/images/icons/Sorting_Icon.png" /></div>,
      enableColumnFilter: true,
      sortingFn: 'basic',
      sortDescFirst: false,
      enableSorting:false,
      // footer: (props) => props.column.id,
    },
    {
      accessorKey: 'flushingmin_imperial',
      size: 120,
      header: () => <div className="tableColumnSubHdr"> <div>Flushing (Min.) </div><img src="/images/icons/Sorting_Icon.png" /></div>,
      enableColumnFilter: true,
      sortingFn: 'basic',
      enableSorting:false,
      // footer: (props) => props.column.id,
    },
    {
      accessorKey: 'flushingmax_imperial',
      size: 120,
      header: () => <div className="tableColumnSubHdr"> <div>Flushing (Max.) </div><img src="/images/icons/Sorting_Icon.png" /></div>,
      enableColumnFilter: true,
      sortingFn: 'basic',
      enableSorting:false,
      // footer: (props) => props.column.id,
    },
    {
      accessorKey: 'comment',
      // size:180,
      header: () => <div className="tableColumnSubHdr"> <div>Comment </div><img src="/images/icons/Sorting_Icon.png" /></div>,
      enableColumnFilter: true,
      sortingFn: 'basic',
      enableSorting:false,
      // footer: (props) => props.column.id,
    },
  ],
},
];
