import {Routes, Route} from "react-router-dom";
import Product from "./component/product/Product";
import Home from "./component/home/Home";


function App() {
  return (
    <>
    <Routes >
      <Route path={"/*"} element={ <Product /> } />
    </Routes>
    </>
  );
}

export default App;
