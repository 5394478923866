import React, { useEffect, useState } from "react";
import {
    useReactTable,
    flexRender,
    getCoreRowModel,
    getSortedRowModel,
} from "@tanstack/react-table";
import { epirocColumnDef } from "./pcomponents/columns";
import { getProductList } from "./ProductAction";

export default function ProductListing(props) {
    const finalColumnDef = React.useMemo(() => epirocColumnDef, []);
    const [sorting, setSorting] = React.useState([]);
    const [data, _setData] = useState([]);
    const [rowSelection, setRowSelection] = React.useState({});
    const [columnVisibility, setColumnVisibility] = React.useState({
        rpmMin: true,
        rpmMax: true,
        wob_metric: true,
        flushingmin_metric: true,
        flushingmax_metric: true,
        wob_imperial: false,
        flushingmin_imperial: false,
        flushingmax_imperial: false,
        comment: true,
    });
    const [tableFilter, setTableFilter] = useState({ si: '', speed: '', wob: '', flushing: '', comment: '' });
    const [SI, US] = useState(true);

    useEffect(() => {
        let flterObj = props.filter;
        console.log("filter log  : ", flterObj);
        fetchData();
        setTableFilter({ ...flterObj });
        US(true);
        if (flterObj != null) {
            console.log("props filetr si : ", flterObj);
            US(props.filter.si);
            setTableFilter({
                si: flterObj.si, speed: flterObj.speed,
                wob: flterObj.wob, flushing: flterObj.flushing,
                comment: flterObj.comment
            })
        }
        if (flterObj != null && flterObj.si == true) {
            console.log("props filter si : ", flterObj);
            setColumnVisibility({
                rpmMin: props.filter.speed,
                rpmMax: props.filter.speed,
                wob_metric: props.filter.wob,
                flushingmin_metric: props.filter.flushing,
                flushingmax_metric: props.filter.flushing,
                wob_imperial: false,
                flushingmin_imperial: false,
                flushingmax_imperial: false,
                comment: props.filter.comment,
            });
            console.log("setColumnVisibility 1 : ", props.filter, columnVisibility);
        } else if (flterObj != null && flterObj.si == false) {
            console.log("props filter imperial ", flterObj);
            setColumnVisibility({
                rpmMin: props.filter.speed,
                rpmMax: props.filter.speed,
                wob_metric: false,
                flushingmin_metric: false,
                flushingmax_metric: false,
                wob_imperial: props.filter.wob,
                flushingmin_imperial: props.filter.flushing,
                flushingmax_imperial: props.filter.flushing, comment: props.filter.comment,
            });
        }
    }, [props.filter, props.reloadProdListing]);

    function getCorrectedData(data) {
        if (data || data != null || data != undefined) {
            return data;
        } else {
            return '';
        }
    }
    async function fetchData() {
        let productList = await getProductList();
        console.log(" fetchData : filter : ", props.filter, " :: filter : ", tableFilter);
        if (productList && productList.length > 0) {
            const respProdList = []
            productList.forEach((prod) => {
                const respProd = {
                    id: prod.id,
                    productNo: prod.productno,
                    productCode: prod.productcode,
                    rpmMin: getCorrectedData(prod.rpm_min) + ' RPM',
                    rpmMax: getCorrectedData(prod.rpm_max) + ' RPM',
                    wob_metric: getCorrectedData(prod.wob_metric) + ' kN',
                    flushingmin_metric: getCorrectedData(prod.flushingmin_metric) + ' lpm',
                    flushingmax_metric: getCorrectedData(prod.flushingmax_metric) + ' lpm',
                    wob_imperial: getCorrectedData(prod.wob_imperial) + ' lb',
                    flushingmin_imperial: getCorrectedData(prod.flushingmin_imperial) + ' gpm',
                    flushingmax_imperial: getCorrectedData(prod.flushingmax_imperial) + ' gpm',
                    comment: getCorrectedData(prod.comment),
                };
                respProdList.push(respProd);
            });
            if (respProdList != null && respProdList.length < 35) {
                while (respProdList.length < 35) {
                    const respProd = {
                        id: (respProdList.length + 1),
                        productNo: '',
                        productCode: '',
                        rpmMin: '',
                        rpmMax: '',
                        wob_metric: '',
                        flushingmin_metric: '',
                        flushingmax_metric: '',
                        wob_imperial: '',
                        flushingmin_imperial: '',
                        flushingmax_imperial: '',
                        comment: "",
                    };
                    respProdList.push(respProd);
                }
            }
            _setData(respProdList);
        } else {
            let i = 0;
            let respList = [];
            while (i < 35) {
                const respProd = {
                    id: (i + 1),
                    productNo: '',
                    productCode: '',
                    rpmMin: '',
                    rpmMax: '',
                    wob_metric: '',
                    flushingmin_metric: '',
                    flushingmax_metric: '',
                    wob_imperial: '',
                    flushingmin_imperial: '',
                    flushingmax_imperial: '',
                    comment: "",
                };
                i = i + 1;
                respList.push(respProd);
            }
            _setData(respList);
        }
    }

    function selectedRowApi(event) {
        setTimeout(() => {
        props.selectedRowsMethod(tableInstance.getSelectedRowModel().flatRows);
        }, 1);
    }

    const tableInstance = useReactTable({
        columns: finalColumnDef,
        data: data,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        state: {
            columnVisibility: columnVisibility,
            sorting: sorting,
        },
        onSortingChange: setSorting,
        onColumnVisibilityChange: setColumnVisibility,
        enableMultiRowSelection: true,
    });

    return (
        <>
            <div  >
                <table className="w-100" onClick={(e) => selectedRowApi(e)}>
                    <thead className="tableHeader tbleSubHeader">
                        {tableInstance.getHeaderGroups().map((headerEl) => {
                            return (
                                <tr key={headerEl.id}>
                                    {headerEl.headers.map((columnEl, index) => {
                                        return (
                                            <th
                                                key={columnEl.id}
                                                colSpan={columnEl.colSpan}
                                                onClick={columnEl.column.getToggleSortingHandler()}
                                                className={`tableHeader ${columnEl.column.columnDef.accessorKey}_${columnEl.id}`}
                                            >
                                                {columnEl.isPlaceholder
                                                    ? null
                                                    : flexRender(
                                                        columnEl.column.columnDef.header,
                                                        columnEl.getContext())
                                                }
                                                {
                                                    { asc: '', desc: '' }
                                                    [
                                                    columnEl.column.getIsSorted()
                                                    ]
                                                }
                                            </th>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </thead>
                    <tbody>
                        {tableInstance.getRowModel().rows.map((rowEl) => {
                            return (
                                <tr key={rowEl.id}
                                    className={rowEl.getIsSelected() ? 'selected tableRow' : 'tableRow'} >
                                    {rowEl.getVisibleCells().map((cellEl) => {
                                        return (
                                            <td key={cellEl.id} className="text-wrap tableBodyCell">
                                                {flexRender(
                                                    cellEl.column.columnDef.cell,
                                                    cellEl.getContext()
                                                )}
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </>
    );
};
